var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-take-photo-itineraries",
      "size": "lg",
      "centered": true,
      "hide-header": true,
      "hide-footer": true,
      "body-class": "p-0"
    },
    on: {
      "show": _vm.onShowHandle,
      "hide": _vm.onHideHandle
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('Stack', {
    staticClass: "p-1 gap-2",
    attrs: {
      "id": "take-photo-itineraries"
    }
  }, [_c('Stack', {
    staticClass: "gap-2",
    attrs: {
      "center": ""
    }
  }, _vm._l(_vm.itineraries.flat(), function (itinerary, itineraryIndex) {
    return _c('ItineraryCard', {
      key: itineraryIndex,
      attrs: {
        "itinerary": itinerary
      }
    });
  }), 1), !_vm.isHidePassengers ? _c('PassengerCard', {
    attrs: {
      "paxs": _vm.paxs
    }
  }) : _vm._e(), !_vm.isHidePrice ? _c('BCard', {
    staticClass: "mb-0 border-info shadow-take-photo-itineraries",
    attrs: {
      "body-class": "p-50 d-flex flex-column gap-2"
    }
  }, [_c('div', {
    staticClass: "d-flex-center flex-column flex-md-row justify-content-md-between mb-25"
  }, [_c('div', {
    staticClass: "bg-blue-gradient w-100 mb-25 mb-md-0 d-flex rounded"
  }, [_c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-alert', {
    staticClass: "p-75 p-md-1 mr-25 mr-md-75 mb-0 rounded",
    staticStyle: {
      "width": "fit-content",
      "background-color": "#3DA5DE1F"
    },
    attrs: {
      "show": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "DollarSignIcon",
      "size": _vm.isMobileView ? '18' : '25'
    }
  })], 1)], 1), _c('div', {
    staticClass: "d-flex flex-column justify-content-center"
  }, [_c('div', {
    class: "text-airline fw-700 mb-0 ".concat(_vm.isMobileView ? 'font-medium-5' : 'font-medium-4')
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.priceDetails')) + " "), _vm.isMobileView ? _c('feather-icon', {
    staticClass: "text-info cursor-pointer",
    attrs: {
      "id": "header-tooltip-mobile-price",
      "icon": "InfoIcon",
      "size": "16"
    }
  }) : _vm._e()], 1), _vm.isMobileView ? _c('b-tooltip', {
    attrs: {
      "target": "header-tooltip-mobile-price",
      "triggers": "hover focus",
      "boundary": "window",
      "variant": "info"
    }
  }, [_c('small', [_vm._v(" " + _vm._s(_vm.$t('flight.priceDetail')))])]) : _vm._e(), !_vm.isMobileView ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('flight.priceDetail')) + " ")]) : _vm._e()], 1)])]), _vm._l(_vm.prices, function (price, priceIndex) {
    return _c('PriceCard', {
      key: priceIndex,
      attrs: {
        "price": price
      }
    });
  }), _vm.ancillaryPrices ? _c('HStack', {
    attrs: {
      "end": ""
    }
  }, [_c('div', {
    staticClass: "text-dark"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.services')) + ": ")]), _c('div', {
    staticClass: "text-18px fw-700 text-warning"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.ancillaryPrices)) + " ")])]) : _vm._e(), _vm.totalPrice ? _c('HStack', {
    attrs: {
      "end": ""
    }
  }, [_c('div', {
    staticClass: "fw-600 text-dark"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.totalCostPrice')) + ": ")]), _c('div', {
    staticClass: "text-22px fw-800 text-warning"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalPrice)) + " ")])]) : _vm._e()], 2) : _vm._e()], 1), _c('HStack', {
    staticClass: "px-1 py-50",
    attrs: {
      "between": ""
    }
  }, [_c('BButton', {
    attrs: {
      "variant": "secondary",
      "size": "sm"
    },
    on: {
      "click": _vm.onCloseModal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('close')) + " ")]), _c('HStack', {
    staticClass: "gap-3",
    attrs: {
      "center": ""
    }
  }, [_c('BFormCheckbox', {
    model: {
      value: _vm.isHidePassengers,
      callback: function callback($$v) {
        _vm.isHidePassengers = $$v;
      },
      expression: "isHidePassengers"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.hidePassengers')) + " ")]), _c('BFormCheckbox', {
    model: {
      value: _vm.isHidePrice,
      callback: function callback($$v) {
        _vm.isHidePrice = $$v;
      },
      expression: "isHidePrice"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.hidePrice')) + " ")]), _c('BButton', {
    staticClass: "px-3",
    attrs: {
      "variant": "success",
      "pill": "",
      "size": "sm"
    },
    on: {
      "click": _vm.onClickTakePhoto
    }
  }, [_c('HStack', [_c('IAmIcon', {
    attrs: {
      "icon": "camera",
      "size": "13"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('reservation.saveImage')) + " ")], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }