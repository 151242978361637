<template lang="">
  <b-modal
    id="modal-take-photo-itineraries"
    size="lg"
    :centered="true"
    :hide-header="true"
    :hide-footer="true"
    body-class="p-0"
    @show="onShowHandle"
    @hide="onHideHandle"
  >
    <IAmOverlay :loading="loading">
      <Stack
        id="take-photo-itineraries"
        class="p-1 gap-2"
      >
        <!-- ANCHOR Itineraries -->
        <Stack
          center
          class="gap-2"
        >
          <ItineraryCard
            v-for="(itinerary, itineraryIndex) in itineraries.flat()"
            :key="itineraryIndex"
            :itinerary="itinerary"
          />
        </Stack>

        <!-- ANCHOR Itineraries -->
        <PassengerCard
          v-if="!isHidePassengers"
          :paxs="paxs"
        />

        <!-- ANCHOR Price -->
        <BCard
          v-if="!isHidePrice"
          class="mb-0 border-info shadow-take-photo-itineraries"
          body-class="p-50 d-flex flex-column gap-2"
        >
          <!-- ANCHOR - Header price card -->
          <div class="d-flex-center flex-column flex-md-row justify-content-md-between mb-25">
            <div class="bg-blue-gradient w-100 mb-25 mb-md-0 d-flex rounded">
              <div class="d-flex-center">
                <b-alert
                  show
                  class="p-75 p-md-1 mr-25 mr-md-75 mb-0 rounded"
                  style="width: fit-content; background-color: #3DA5DE1F"
                >
                  <feather-icon
                    icon="DollarSignIcon"
                    :size="isMobileView ? '18' : '25'"
                  />
                </b-alert>
              </div>

              <div class="d-flex flex-column justify-content-center">
                <div :class="`text-airline fw-700 mb-0 ${isMobileView ? 'font-medium-5' : 'font-medium-4'}`">
                  {{ $t('flight.priceDetails') }}
                  <feather-icon
                    v-if="isMobileView"
                    id="header-tooltip-mobile-price"
                    icon="InfoIcon"
                    size="16"
                    class="text-info cursor-pointer"
                  />
                </div>

                <b-tooltip
                  v-if="isMobileView"
                  target="header-tooltip-mobile-price"
                  triggers="hover focus"
                  boundary="window"
                  variant="info"
                >
                  <small> {{ $t('flight.priceDetail') }}</small>
                </b-tooltip>

                <span v-if="!isMobileView">
                  {{ $t('flight.priceDetail') }}
                </span>
              </div>
            </div>
          </div>
          <PriceCard
            v-for="(price, priceIndex) in prices"
            :key="priceIndex"
            :price="price"
          />

          <!-- ANCHOR DICH VU va TONG -->
          <HStack
            v-if="ancillaryPrices"
            end
          >
            <div class="text-dark">
              {{ $t('reservation.services') }}:
            </div>
            <div class="text-18px fw-700 text-warning">
              {{ formatCurrency(ancillaryPrices) }}
            </div>
          </HStack>

          <HStack
            v-if="totalPrice"
            end
          >
            <div class="fw-600 text-dark">
              {{ $t('reservation.totalCostPrice') }}:
            </div>
            <div class="text-22px fw-800 text-warning">
              {{ formatCurrency(totalPrice) }}
            </div>
          </HStack>
        </BCard>
      </Stack>
      <!-- ANCHOR Buttons -->
      <HStack
        between
        class="px-1 py-50"
      >
        <BButton
          variant="secondary"
          size="sm"
          @click="onCloseModal"
        >
          {{ $t('close') }}
        </BButton>

        <HStack
          center
          class="gap-3"
        >
          <BFormCheckbox v-model="isHidePassengers">
            {{ $t('reservation.hidePassengers') }}
          </BFormCheckbox>

          <BFormCheckbox v-model="isHidePrice">
            {{ $t('reservation.hidePrice') }}
          </BFormCheckbox>
          <BButton
            variant="success"
            pill
            size="sm"
            class="px-3"
            @click="onClickTakePhoto"
          >
            <HStack>
              <IAmIcon
                icon="camera"
                size="13"
              />
              {{ $t('reservation.saveImage') }}
            </HStack>
          </BButton>
        </HStack>
      </HStack>
    </IAmOverlay>
  </b-modal>
</template>
<script>
import {
  computed, nextTick, ref, toRef,
} from '@vue/composition-api'
import {
  BButton, BModal, BFormCheckbox, BCard,
  BAlert,
} from 'bootstrap-vue'
import html2canvas from 'html2canvas-pro'
import { groupBy } from 'lodash-es'

import store from '@/store'
import { checkTransitAlert, convertISODateTime, formatCurrency } from '@/@core/utils/filter'
import VueI18n from '@/libs/i18n'

import { useParseTicketData } from '../useParseTicketData'

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BFormCheckbox,
    ItineraryCard: () => import('./ItineraryCard.vue'),
    PriceCard: () => import('./PriceCard.vue'),
    PassengerCard: () => import('./PassengerCard.vue'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    BAlert,
  },
  props: {
    bookingData: {
      type: Object,
      default: null,
    },
    isCombination: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root }) {
    const RESERVATION_APP_STORE_MODULE_NAME = 'app-reservation'
    const isHidePassengers = ref(false)
    const isHidePrice = ref(false)
    const bookingData = toRef(props, 'bookingData')
    const isCombination = toRef(props, 'isCombination')
    const bookingArray = ref([])
    const itineraries = ref([])
    const paxs = ref([])
    const prices = ref([])
    const ancillaryPrices = ref([])
    const loading = ref(false)

    function onHideHandle() {
      bookingArray.value = []
      itineraries.value = []
      paxs.value = []
      prices.value = []
      ancillaryPrices.value = []
    }

    const locale = computed(() => VueI18n.locale).value

    function getAirportByAirportCode(airportCode) {
      if (!airportCode) return null
      return store.getters[`${RESERVATION_APP_STORE_MODULE_NAME}/getAirportByAirportCode`](airportCode)
    }
    function getAirportName(airportCode, showCode = true) {
      if (!airportCode) return null
      const airportObj = getAirportByAirportCode(airportCode)
      if (!airportObj) return airportCode

      let airportName
      const airportCodeText = showCode ? ` (${airportCode})` : ''
      if (airportObj.group === 'VIỆT NAM' || airportObj.group === 'VIETNAM') {
        airportName = `${this.$t(`vnAirports.${airportCode}`)}${airportCodeText}`
      } else {
        const isVn = locale === 'vi'
        const cityName = isVn ? airportObj.city : airportObj.cityEnName
        const countryName = isVn ? airportObj.countryName : airportObj.countryEnName
        airportName = `${cityName}${airportObj.countryName === 'Việt Nam' ? '' : `, ${countryName}`}${airportCodeText}`
      }
      return airportName
    }
    function resolvePaxFareInfoTicket(paxFareInfoTicket) {
      const { paxInfo } = paxFareInfoTicket
      const paxInfoGroup = groupBy(paxInfo, item => item.paxType)
      const res = Object.keys(paxInfoGroup).reduce((result, key) => {
        result[key] = {
          paxType: key,
          amountPassenger: paxInfoGroup[key].length,
          netPrice: paxInfoGroup[key].reduce((netPrice, item) => netPrice + item.netPrice, 0),
          discount: paxInfoGroup[key].reduce((discount, item) => discount + item.discount, 0),
          discountPostpaid: paxInfoGroup[key].reduce((discountPostpaid, item) => discountPostpaid + item.discountPostpaid, 0),
          fee: paxInfoGroup[key].reduce((fee, item) => fee + item.fee, 0),
          feeService: paxInfoGroup[key].reduce((feeService, item) => feeService + item.feeService, 0),
          tax: paxInfoGroup[key].reduce((tax, item) => tax + item.tax, 0),
          total: paxInfoGroup[key].reduce((total, item) => total + item.total, 0),
        }
        return result
      }, {})

      return Object.values(res)
    }

    function getPricesByPriceQuotes(quoteList, paxLists, ancillaryServices, source) {
      const result = []
      for (const priceQuote of quoteList) {
        const amount = priceQuote.appliedPassengers.length
        const paxType = priceQuote.appliedPassengers[0].paxType

        const ancillaryPaxType = ancillaryServices.filter(ancillary => {
          const pax = paxLists.find(pax => pax.paxId === ancillary.paxId)
          return pax?.paxType === paxType
        })
        const ancillaryPrice = {
          netPrice: props.config.showAncillaries ? 0 : ancillaryPaxType.reduce((sum, item) => sum + item.basePrice, 0),
          tax: props.config.showAncillaries ? 0 : ancillaryPaxType.reduce((sum, item) => sum + item.totalTax, 0),
          total: props.config.showAncillaries ? 0 : ancillaryPaxType.reduce((sum, item) => sum + item.totalPrice, 0),
        }

        result.push({
          paxType,
          flight: priceQuote.bookingInfos ? priceQuote.bookingInfos.map(item => `${getAirportName(item.departure)} - ${getAirportName(item.arrival)}`).join(', ') : '',
          departureDate: convertISODateTime(priceQuote.bookingInfos[0].departureDate, priceQuote.bookingInfos[0].departureTimezone).ISOdatetime,
          segmentId: priceQuote.bookingInfos ? priceQuote.bookingInfos.map(item => `${item.segmentId}`).join(', ') : '',
          amountPassenger: amount,
          fareBasisCode: priceQuote.fareInfos.map(fare => fare.fareBasisCode).join(', '),
          expirationTime: bookingData.value?.source === '1G' ? convertISODateTime(priceQuote.expirationTime).timeDate : convertISODateTime(priceQuote.expirationTime).timeDate,
          lastDateToPurchase: convertISODateTime(priceQuote.lastDateToPurchase).timeDate,
          feeService: (priceQuote.feeService || 0) * amount,
          netPrice: (priceQuote.basePriceTicket || priceQuote.basePrice) * amount + ancillaryPrice.netPrice,
          tax: (priceQuote.totalTaxesTicket || priceQuote.totalTaxes) * amount + ancillaryPrice.tax,
          total: (priceQuote.totalPriceTicket || priceQuote.totalPrice) * amount + ancillaryPrice.total,
        })
      }
      const groupByArray = Object.values(groupBy(result, ({ flight, segmentId }) => `${segmentId}|${flight}`))
        .sort((a, b) => Number(a[0].segmentId) - Number(b[0].segmentId))
      const res = groupByArray.map(item => ({
        trip: item[0].flight,
        departureDate: item[0].departureDate,
        paxFare: item,
        total: item.reduce((a, c) => a + c.total, 0),
        source,
      }))
      return res
    }
    function convertBookingDataToFare(bookingData) {
      if (bookingData.source === 'F1') {
        const passengerGroupBy = groupBy(bookingData.paxLists, item => item.paxType)
        const passenger = Object.keys(passengerGroupBy).map(key => `${root.$t(`reservation.${key}`)} x${passengerGroupBy[key].length}`).join(', ')
        const fareTotal = bookingData.paxFareInfoTicket.reduce((sum, paxFareInfoTicketItem) => sum + paxFareInfoTicketItem.paxInfo.reduce((s, pax) => s + pax.total, 0), 0)
        return [{
          trip: bookingData.itineraries.map(itinerary => itinerary.map(segment => `${getAirportName(segment.departure.iataCode)} - ${getAirportName(segment.arrival.iataCode)}`).join(', ')).join(', '),
          fareInfoTicket: [{
            total: fareTotal,
            passenger,
          }],
          total: fareTotal,
          source: bookingData.source,
        }]
      }
      if (bookingData?.paxFareInfoTicket?.length) {
        return bookingData.paxFareInfoTicket.map(paxFareInfoTicketItem => {
          const paxFare = resolvePaxFareInfoTicket(paxFareInfoTicketItem)
          return {
            trip: paxFareInfoTicketItem.trips.map(trip => `${getAirportName(trip.departure)} - ${getAirportName(trip.arrival)}`).join(', '),
            departureDate: convertISODateTime(paxFareInfoTicketItem?.trips[0]?.departureDate, paxFareInfoTicketItem?.trips[0]?.departureTz).ISOdatetime,
            paxFare,
            total: paxFare.reduce((a, cur) => a + cur.total, 0),
            source: bookingData.source,
          }
        })
      }
      if (bookingData?.priceQuotes?.length) {
        return getPricesByPriceQuotes(bookingData.priceQuotes, bookingData.paxLists, bookingData.ancillaryServices, bookingData.source)
      }
      if (!bookingData?.priceQuotes?.length && bookingData?.historicalPriceQuotes?.length) {
        return getPricesByPriceQuotes(bookingData.historicalPriceQuotes, bookingData.paxLists, bookingData.ancillaryServices, bookingData.source)
      }
      return null
    }

    function onShowHandle() {
      loading.value = true

      const {
        paxs: paxsValue,
      } = useParseTicketData(bookingData.value, true, locale)

      bookingArray.value.push({ ...bookingData.value, roundtripBooking: null, multiCityBookings: null })
      if (isCombination.value) {
        if (bookingData.value?.roundtripBooking) {
          bookingArray.value.push(bookingData.value.roundtripBooking)
        } else if (bookingData.value.multiCityBookings) {
          bookingArray.value.push(...bookingData.value.multiCityBookings.filter(booking => !['CANCEL'].includes(booking?.status)))
        }
        bookingArray.value.sort((a, b) => a?.id - b?.id ? 1 : -1)
      }

      itineraries.value = bookingArray.value.map(b => b.itineraries.map(itinerary => itinerary.map((segment, indexSegment) => {
        const nextSegment = indexSegment < itinerary.length - 1 ? itinerary?.[indexSegment + 1] : null
        if (nextSegment) {
          const { isNextAirport, isNextDay, transitTime } = checkTransitAlert(segment.arrival, nextSegment.departure)
          return {
            ...segment,
            isNextAirport,
            isNextDay,
            transitTime,
          }
        }
        return segment
      })))
      prices.value = bookingArray.value
        .map(booking => convertBookingDataToFare(booking))
        .flatMap(a => a)
        .filter(Boolean)
        .sort((a, b) => (new Date(a.departureDate)) - (new Date(b.departureDate)))

      ancillaryPrices.value = bookingArray.value
        .filter(booking => !booking?.source?.includes('1S'))
        .reduce((acc, item) => acc + item.ancillaryServices.reduce((sum, a) => sum + a.totalPrice, 0), 0)
      paxs.value = paxsValue

      nextTick(() => {
        loading.value = false
      })
    }

    const totalPrice = computed(() => (prices.value.reduce((a, c) => a + c.total, 0) + (ancillaryPrices.value || 0)))

    function onCloseModal() {
      this.$bvModal.hide('modal-take-photo-itineraries')
    }

    async function exportItinerary(type = 'img') {
      const IMAGE_SCALE = 2
      root.$bvModal.show('modal-api-loading')
      store.dispatch('app/setTextLoading', 'Bắt đầu chụp ảnh hành trình...')
      // eslint-disable-next-line no-restricted-syntax
      console.time('exportItinerary')

      const exportElement = document.getElementById('take-photo-itineraries')
      exportElement.style.maxWidth = '1200px'

      await html2canvas(exportElement, {
        scrollX: 0,
        scrollY: -window.scrollY,
        useCORS: true,
        scale: IMAGE_SCALE,
      })
        .then(canvas => {
          if (type === 'img') {
            const image = canvas.toDataURL('image/png', {
              allowTaint: false,
              useCORS: true,
              quality: 1,
            })
            const link = document.createElement('a')
            link.href = image
            const fileName = `HanhTrinh_${bookingArray.value.map(booking => `${booking.airports.replaceAll(' ', '')}_${convertISODateTime(booking.flightDate).date.replaceAll('/', '')}`)
              .join('_')}.png`
            link.download = fileName
            store.dispatch('app/setTextLoading', `Tải xuống ảnh ${fileName}...`)
            link.click()
          }
        })
        .finally(() => {
          exportElement.style.maxWidth = 'unset'
        })

      root.$bvModal.hide('modal-api-loading')
      store.dispatch('app/setTextLoading', null)

      // eslint-disable-next-line no-restricted-syntax
      console.timeEnd('exportItinerary')
    }

    function onClickTakePhoto() {
      exportItinerary()
    }
    return {
      loading,
      onCloseModal,
      onClickTakePhoto,
      isHidePrice,
      isHidePassengers,
      onShowHandle,
      itineraries,
      prices,
      paxs,
      onHideHandle,
      totalPrice,
      formatCurrency,
      ancillaryPrices,
    }
  },
}
</script>
<style lang="scss" scoped>
::v-deep .modal-backdrop {
  opacity: 0.87 !important;
}
.shadow-take-photo-itineraries {
  box-shadow: 0px 4px 10px 0px #0000004D;
}
</style>
